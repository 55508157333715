import React, { Suspense, useState, useEffect } from "react";
import { Switch, Redirect } from "react-router-dom";
import PrivateRoute from 'components/PrivateRoute'
import { strings } from "res";
import Loading from 'components/shared-components/Loading';
import jwt_decode from 'jwt-decode'

// Pages
import DASHBOARD from "./dashboard"
import CATEGORIES from "./categories"
import CONTENTS from "./contents"
import LAYANAN from "./layanan"
import KELURAHAN from "./kelurahan"
import UNDUHAN from "./unduhan"
import PENGUMUMAN from "./pengumuman"
import BERITA from "./berita"
import USERS from "./users"
// import LAPORAN from "./laporan"

// Detail
import DETAIL_CATEGORY from "./detail-category"
import DETAIL_CONTENT from "./detail-content"
import DETAIL_LAYANAN from "./detail-layanan"
import DETAIL_BERITA from "./detail-berita"
import DETAIL_KELURAHAN from "./detail-kelurahan"
import DETAIL_USER from "./detail-user"
import DETAIL_UNDUHAN from "./detail-unduhan"
import DETAIL_PENGUMUMAN from "./detail-pengumuman"

import SETTINGS from "./settings"

const all_privileges = [1, 2]
const admin_privileges = [1]

export const AppViews = ({ match }) => {
  const [privilege, setPrivilege] = useState(2)
  useEffect(() => {
    try {
      if (localStorage.getItem('token')) {
        const token = localStorage.getItem('token');
        const decoded = jwt_decode(token);
        console.log(decoded.role_id)

        setPrivilege(decoded.role_id)
      }
    } catch (err) {
      console.log({ err })
    }
  }, [])
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <PrivateRoute
          privileges={all_privileges}
          requiredPrivilege={privilege}
          path={`${strings.navigation.path.dashboard}`} component={DASHBOARD} />
        <PrivateRoute
          privileges={all_privileges}
          requiredPrivilege={privilege}
          path={`${strings.navigation.path.categories}`} component={CATEGORIES} />
        <PrivateRoute
          privileges={all_privileges}
          requiredPrivilege={privilege}
          path={`${strings.navigation.path.contents}`} component={CONTENTS} />
        <PrivateRoute
          privileges={all_privileges}
          requiredPrivilege={privilege}
          path={`${strings.navigation.path.layanan}`} component={LAYANAN} />
        <PrivateRoute
          privileges={all_privileges}
          requiredPrivilege={privilege}
          path={`${strings.navigation.path.kelurahan}`} component={KELURAHAN} />
        <PrivateRoute
          privileges={all_privileges}
          requiredPrivilege={privilege}
          path={`${strings.navigation.path.unduhan}`} component={UNDUHAN} />
        <PrivateRoute
          privileges={all_privileges}
          requiredPrivilege={privilege}
          path={`${strings.navigation.path.pengumuman}`} component={PENGUMUMAN} />
        <PrivateRoute
          privileges={all_privileges}
          requiredPrivilege={privilege}
          path={`${strings.navigation.path.berita}`} component={BERITA} />
        <PrivateRoute
          privileges={all_privileges}
          requiredPrivilege={privilege}
          path={`${strings.navigation.path.users}`} component={USERS} />
        {/* <PrivateRoute path={`${strings.navigation.path.laporan}`} component={LAPORAN} /> */}

        {/* DETAIL */}
        <PrivateRoute
          privileges={all_privileges}
          requiredPrivilege={privilege}
          path={`${strings.navigation.path.detail_content}`} component={DETAIL_CONTENT} />
        <PrivateRoute
          privileges={all_privileges}
          requiredPrivilege={privilege}
          path={`${strings.navigation.path.detail_layanan}`} component={DETAIL_LAYANAN} />
        <PrivateRoute
          privileges={all_privileges}
          requiredPrivilege={privilege}
          path={`${strings.navigation.path.detail_layanan}`} component={DETAIL_LAYANAN} />
        <PrivateRoute
          privileges={all_privileges}
          requiredPrivilege={privilege}
          path={`${strings.navigation.path.detail_kelurahan}`} component={DETAIL_KELURAHAN} />
        <PrivateRoute
          privileges={all_privileges}
          requiredPrivilege={privilege}
          path={`${strings.navigation.path.detail_category}`} component={DETAIL_CATEGORY} />
        <PrivateRoute
          privileges={all_privileges}
          requiredPrivilege={privilege}
          path={`${strings.navigation.path.detail_user}`} component={DETAIL_USER} />
        <PrivateRoute
          privileges={all_privileges}
          requiredPrivilege={privilege}
          path={`${strings.navigation.path.detail_berita}`} component={DETAIL_BERITA} />
        <PrivateRoute
          privileges={all_privileges}
          requiredPrivilege={privilege}
          path={`${strings.navigation.path.detail_unduhan}`} component={DETAIL_UNDUHAN} />
        <PrivateRoute
          privileges={all_privileges}
          requiredPrivilege={privilege}
          path={`${strings.navigation.path.detail_pengumuman}`} component={DETAIL_PENGUMUMAN} />

        <PrivateRoute
          privileges={all_privileges}
          requiredPrivilege={privilege}
          path={`${strings.navigation.path.settings}`} component={SETTINGS} />

        <Redirect from={`${match.url}`} to={`${strings.navigation.path.dashboard}`} />
      </Switch>
    </Suspense>
  )
}

export default AppViews;
