import { strings } from 'res';
import { DashboardIcon, InsightIcon, Megaphone, OrderIcon, ReportIcon, SLAIcon } from "../assets/svg/icon";
import { UserOutlined, DownCircleOutlined, InfoCircleOutlined, BookOutlined,SettingOutlined } from '@ant-design/icons';

const all_access = [1]
const reguler_access = [1,2]
const desa = [3]

const dashBoardNavTree = [{
  key: "Home",
  path: strings.navigation.path.dashboard,
  title: "Home",
  breadcrumb: false,
  submenu: [
    {
      key: "Dashboard",
      path: strings.navigation.path.dashboard,
      title: "Dashboard",
      icon: DashboardIcon,
      access:all_access,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Contents",
      path: strings.navigation.path.contents,
      title: "Contents",
      icon: ReportIcon,
      access:reguler_access,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Layanan",
      path: strings.navigation.path.layanan,
      title: "Layanan",
      icon: InsightIcon,
      access:reguler_access,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Berita",
      path: strings.navigation.path.berita,
      title: "Berita",
      icon: InfoCircleOutlined,
      access:reguler_access,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Unduhan",
      path: strings.navigation.path.unduhan,
      title: "Unduhan",
      icon: DownCircleOutlined,
      breadcrumb: false,
      access:reguler_access,
      submenu: []
    },
    {
      key: "Pengumuman",
      path: strings.navigation.path.pengumuman,
      title: "Pengumuman",
      icon: Megaphone,
      access:reguler_access,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Kelurahan",
      path: strings.navigation.path.kelurahan,
      title: "Kelurahan",
      icon: SLAIcon,
      access:reguler_access,
      breadcrumb: false,
      submenu: []
    },
    // {
    //   key: "Categories",
    //   path: strings.navigation.path.categories,
    //   title: "Categories",
    //   icon: OrderIcon,
    //   breadcrumb: false,
    //   submenu: []
    // },
    {
      key: "Users",
      path: strings.navigation.path.users,
      title: "Users",
      icon: UserOutlined,
      access:reguler_access,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Laporan",
      path: strings.navigation.path.laporan,
      title: "Laporan",
      icon: BookOutlined,
      access:reguler_access,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

const ControlTree = [{
  key: "Akun",
  path: strings.navigation.path.settings,
  title: "Akun",
  breadcrumb: false,
  submenu: [
    {
      key: "Pengaturan",
      path: strings.navigation.path.settings,
      title: "Pengaturan",
      icon: SettingOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

const navigationConfig = [
  ...dashBoardNavTree,
  ...ControlTree,
]

export default navigationConfig;